import { FORM_INPUTS } from 'src/types/form';
import { IFormGeneratorGeneralSchemaType } from 'src/types/form-generator-schema-type';

export type createSchemaFields = 'dollar' | 'new_currency' | 'currency'
export type createSchemaType = Record<createSchemaFields, IFormGeneratorGeneralSchemaType>;
export const createCurrencyExchangeSchema: createSchemaType = {
    dollar: {
        placeholder: 'USD',
        type: FORM_INPUTS.TEXT,
        defaultValue: 1,
        props: {
            disabled: true
        }
    },
    new_currency: {
        placeholder: 'Source Currency',
        data: [],
        type: FORM_INPUTS.SELECT,
        props: {
            required: true,
        }
    },
    currency: {
        placeholder: 'Source Currency Value',
        type: FORM_INPUTS.TEXT,
        props: {
            required: true
        }
    }
};
