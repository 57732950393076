import React, { Dispatch, memo, SetStateAction } from 'react';
import DashboardTitle from 'src/components/App/DashboardTitle';
import Table from 'src/components/App/Table/index';
import Button from 'src/components/Kit/Button';
import { Helmet } from 'react-helmet-async';
import { Column } from 'primereact/column';
import ROUTE_CONSTANTS from 'src/Routes/constants/route-constants';
import DashboardContentWrapper from 'src/layouts/DashboardContentWrapper';
import EditIcon from 'src/assets/Icons/EditIcon';
import { useCheckPermissionAccess } from 'src/hooks/useCheckPermissionAccess';
import { PERMISSION_TYPES } from 'src/enums/permissions';
import { IStockExchangeListResponse } from 'src/api/types/stock-exchanges';
import { cellRender } from './cellRender';
import { currencyExchangeRateService } from 'src/api/services/currency-exchanges-service';
import { Message } from 'primereact/message';

const service = async () => currencyExchangeRateService.getAll();

const CurrencyExchangeList = memo(() => {
    const { checkPermissionAccess } = useCheckPermissionAccess();

    const suffixCols = (data: IStockExchangeListResponse[], setTableData: Dispatch<SetStateAction<IStockExchangeListResponse[]>>) => {
        return (
            <Column
                key="Action"
                body={(rowData: any) => (
                    <>
                        {checkPermissionAccess(PERMISSION_TYPES.CURRENCY_EXCHANGE_EDIT) && (
                            <Button
                                link
                                color="secondary"
                                size="m"
                                iconOnly
                                disabled={!rowData?.is_editable}
                                to={ROUTE_CONSTANTS.SETTINGS.CURRENCY_EXCHANGE.UPDATE.EDIT_BY_ID(1).ABSOLUTE}
                            >
                                <EditIcon />
                            </Button>
                        )}
                    </>
                )}
            />
        );
    };

    return (
        <>
            <Helmet>
                <title>Teaser | Currency Exchange Rate List</title>
            </Helmet>
            <DashboardTitle
                title={'Currency Exchange Rate List'}
                titleSuffix={
                    checkPermissionAccess(PERMISSION_TYPES.CURRENCY_EXCHANGE_EDIT) ? (
                        <Button size="s" link color="primary" to={ROUTE_CONSTANTS.SETTINGS.CURRENCY_EXCHANGE.ADD_NEW.ROOT.ABSOLUTE}>
                            Add/Modify New Currency Exchange Rate
                        </Button>
                    ) : null
                }
            />
            <div className={'w-full p-4 text-black'}>
                <Message
                    className="w-full justify-content-start p-4"
                    severity="warn"
                    text={<>
                        Your are permitted to update each currency exchange rate
                        <strong> only once within a 24-hour period</strong>
                    </>}
                />
            </div>
            <DashboardContentWrapper>
                {checkPermissionAccess(PERMISSION_TYPES.CURRENCY_EXCHANGE_LIST) && (
                    <Table getTableData={service} cellRender={cellRender} suffixCols={suffixCols} />
                )}
            </DashboardContentWrapper>
        </>
    );
})

export default CurrencyExchangeList;
