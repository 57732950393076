import { API_ROUTES } from 'src/constants/api-routes';
import { AxiosInstance } from '../index';
import { IAxiosResponse } from '../types/axios-response';
import { IDataTableResponse } from 'src/api/types/table';

export const currencyExchangeRateService = {
    getAll() {
        return AxiosInstance.get<IDataTableResponse<any>>(API_ROUTES.CURRENCY_EXCHANGE.GET_ALL);
    },
    update(data: any) {
        return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.CURRENCY_EXCHANGE.MODIFY, data);
    }
};
